import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { JwtService, UserRoles } from './jwt.service';
import { OrganisationService } from './organisation.service';
import { EventModel } from '../models/event.model';
import { EventsService } from './events.service';
import { CourseModel } from '../models/course.model';
import { UndoAssessmentService } from '../pages/events/event/units/undo-assessment.service';
import { Router } from '@angular/router';
import { AnalyticsService } from './analytics.service';
import { UsersService } from './users.service';
import { CognitoSSOSession } from './sso.service';

export enum Roles {
  ORG_ADMIN = "ORG_ADMIN",
  DEP_ADMIN = "DEP_ADMIN",
  SUBORG_ADMIN = "SUBORG_ADMIN",
  ASSESSOR = "ASSESSOR",
  PARTNERSHIP = "PARTNERSHIP",
  REVIEWER = "REVIEWER",
  LEVEL_1_REVIEWER = 'LEVEL_1_REVIEWER',
  LEVEL_2_REVIEWER = 'LEVEL_2_REVIEWER'
}

export type IAuthResponse = { IdToken?: string; };

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private organisationService: OrganisationService,
    private eventsService: EventsService,
    private undoAssessmentService: UndoAssessmentService,
    private router: Router,
    private usersService: UsersService,
    private analytics: AnalyticsService,
  ) {
    window.addEventListener('storage', this.handleUserStorageChange.bind(this));
  }

  public login(username: string, password: string, appRequestPath: string | null = null): Observable<IAuthResponse> {
    const orgId: string = this.organisationService.organisation.orgId;

    return this.http.post<IAuthResponse>(environment.api.login, {
      username,
      password,
      orgId
    }, {
      headers: {
        ...((appRequestPath && appRequestPath.length) && {
          'x-etu-app-request-path': appRequestPath
        }),
      }
    }).pipe(
      switchMap(response => {
        this.setLoggedInViaSSO(false);
        return this.handleAuthResponse(response)
      }),
      tap(() => {
        // tap so we don't break login if analytics call fails
        void this.analytics.identify();
      }),
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  public sso(ssoSession: CognitoSSOSession): Observable<IAuthResponse> {
    const orgId: string = this.organisationService.organisation.orgId;

    return this.http.post<IAuthResponse>(environment.api.cognitoSso, {
      orgId,
      accessToken: ssoSession.accessToken
    }).pipe(
      switchMap(response => {
        this.setLoggedInViaSSO(true);
        return this.handleAuthResponse(response);
      }),
      tap(() => {
        // tap so we don't break login if analytics call fails
        void this.analytics.identify();
      }),
    );
  }

  private handleAuthResponse(response: IAuthResponse): Observable<IAuthResponse> {
    if (response.IdToken) {
      localStorage.setItem('current_user', response.IdToken);
      localStorage.removeItem('filterSettings');
      this.undoAssessmentService.resetUndoActions();
      return of(response);
    }

    return throwError(new Error('Unknown User'));
  }

  public loginWithJwt(jwt: string) {
    localStorage.setItem('current_user', jwt);
    localStorage.removeItem('filterSettings');
    this.undoAssessmentService.resetUndoActions();
    if (!this.jwtService.getUserId()) {
      localStorage.setItem('current_user', '');
    }
  }

  public logout() {
    localStorage.removeItem('current_user');
    localStorage.removeItem('loggedInViaSSO');
    this.usersService.invalidateCache();
    void this.analytics.reset();
  }

  public isAuthenticated(): boolean {
    // do authentication check here, this will be used by router guard to determine if user is logged in or not
    if (localStorage.getItem('current_user')
      && (localStorage.getItem('current_user') !== '' && localStorage.getItem('current_user') !== 'undefined')) {
      return true;
    } else {
      return false;
    }
  }

  public notParticipant(): boolean {
    if (
      (this.jwtService.getRoles().includes(UserRoles.ASSESSOR) ||
        this.jwtService.getRoles().includes(UserRoles.ACCOUNT_ADMIN) ||
        this.jwtService.getRoles().includes(UserRoles.TRAINER) ||
        this.jwtService.getRoles().includes(UserRoles.LEVEL_1_REVIEWER) ||
        this.jwtService.getRoles().includes(UserRoles.LEVEL_2_REVIEWER) ||
        this.jwtService.getRoles().includes(UserRoles.ENDORSED_DELEGATE))
    ) {
      return true;
    } else {
      return false;
    }
  }


  // This function checks if you are an assessor in the given course
  public checkCourseAssessorStatus(course: CourseModel, event: EventModel) {
    return !!(this.eventsService.getEventAssessors(event).filter(assessor => {
      if (this.jwtService.getUserId() === assessor.id && assessor.courses.includes(course.id)) {
        return assessor;
      }
    })).length;
  }

  public getToken(): string {
    return localStorage.getItem('current_user');
  }


  /**
   * Navigate user to login page if user is not authenticated on user storage change.
   * @param event StorageEvent
   * @returns void
   */
  private handleUserStorageChange(event: StorageEvent): void {
    if (event.key !== 'current_user') {
      return;
    }

    if (!this.isAuthenticated()) {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Set previous url in local storage.
   * @returns void
   */
  public setPreviousUrl(): void {
    const url = location.pathname + location.search;
    const previousUrlBlacklist = ['/login'];

    if (previousUrlBlacklist.includes(url)) {
      return;
    }

    localStorage.setItem('previous_url', url);
  }

  /**
   * Get previous url from local storage and clear it.
   * @returns string
   */
  public getPreviousUrl(): string {
    const previousUrl = localStorage.getItem('previous_url');
    localStorage.removeItem('previous_url');

    return previousUrl;
  }

  public wasLoggedInViaSSO(): boolean {
    const loggedInViaSSo = localStorage.getItem('loggedInViaSSO');
    return loggedInViaSSo === 'true';
  }

  public setLoggedInViaSSO(value: boolean): void {
    const loggedInViaSSo = localStorage.setItem('loggedInViaSSO', JSON.stringify(value));
  }
}
